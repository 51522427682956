

.logo {
  display: inline-block;
  padding: 0.5rem;
  text-decoration: none;
  font-size: 1.5rem;
  filter: invert(100%) hue-rotate(180deg);
}

.logo-link {
  width: 10em;
}

li {
  color: aliceblue;
}

nav {
  height: inherit;
}

.nav-list-right-wrapper {
  display: flex;
  gap: 1rem;;
}


.dropdown > li {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
}

.dropdown:hover .dropdown-nav {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: opacity 0.3s ease-out;
}

.dropdown-nav {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  transform: translateY(-40rem);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: -25.5rem;
  width: 60rem;
  margin-top: 1rem !important;
  height: 30rem;
  background-color: white;
  border-radius: 25px;
}
.dropdown-nav li {
  font-weight: 300;
  font-size: 1.2rem;
}

.dropdown-nav2 {
  opacity: 0;
  /*transition: opacity 1s cubic-bezier(0.88, -0.72, 0, 1), opacity 1s ease-in-out;*/
  transform: translateY(-4rem);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  top: 6.7rem;
  left: -20rem;
  width: 68rem;
  height: 28rem;
  background-color: white;
}
.dropdown-nav2 li {
  font-weight: 300;
  font-size: 1.2rem;
}

.dropdown-nav3 {
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1), opacity 0.3s ease-in-out;
  transform: translateY(-4rem);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  top: 6.7rem;
  left: -33rem;
  width: 71rem;
  height: 28rem;
  background-color: white;
}
.dropdown-nav3 li {
  font-weight: 300;
  font-size: 1.2rem;
}

.dropdown-nav4 {
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1), opacity 0.3s ease-in-out;
  transform: translateY(-4rem);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  top: 6.7rem;
  left: -37rem;
  width: 71rem;
  height: 28rem;
  background-color: white;
}
.dropdown-nav4 li {
  font-weight: 300;
  font-size: 1.2rem;
}

.banner {
  height: 4em;
  background-color: #031D44;
  color: aliceblue;
}

.cart-icon {  
  margin-left: 20em;

}


  /* UNDSELINE 
/* .nav-link {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #031D44;
  padding: 60px 0px;
  margin: 0px 20px;
  display: inline-block;
  position: relative;
  opacity: 0.75;
  padding: 1.8rem !important;
}

.nav-link-fade-up:hover::before {
  bottom: 10px;
  opacity: 1;
}

.nav-link-fade-up::before {
  width: 80%;
  bottom: 5px;
  opacity: 0;
}

/* .nav-link:hover {
  opacity: 1;
} */

.nav-link {
  height: 100%;
  margin-left: 1rem;
}

.nav-link-underline {
  &:hover {
    text-decoration: underline;
  }
}

.nav-link::before {
  transition: 400ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #031D44;
}

.navbar {
  justify-content: space-between !important;
}

.profile-btn {
  display: flex;
  flex-direction: column;
}

.MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  padding-top: 8px;
  padding-bottom: 8px;

  li {
    padding: 6px 16px;
  }
}

.navLine {
  left: 56.8rem;
  width: 1.5px !important;
  top: 2.8rem !important;
  background-color: #031D44;
}/*# sourceMappingURL=Navbar.css.map */