

.logo {
    display: inline-block;
    padding: 0.5rem;
    text-decoration: none;
    font-size: 1.5rem;
}

li {
    color: #173f35;
}


// /*   UNDSELINE  */


// .nav-link {
//     font-weight: bold;
//     font-size: 14px;
//     text-transform: uppercase;
//     text-decoration: none;
//     color: #000000;
//     padding: 60px 0px;
//     margin: 0px 20px;
//     display: inline-block;
//     position: relative;
//     opacity: 0.75;
//     padding: 1.8rem !important;
// }


// .nav-link-fade-up:hover::before {
//     bottom: 10px;
//     opacity: 1;
// }


// .nav-link-fade-up::before {
//     width: 80%;
//     bottom: 5px;
//     opacity: 0;
// }


// .nav-link:hover {
//     opacity: 1;
// }


// .nav-link::before {
//     transition: 400ms;
//     height: 5px;
//     content: "";
//     position: absolute;
//     background-color: #031D44;
// }

// .navLine {
//     left: 56.8rem;
//     width: 1.5px !important;
//     top: 2.8rem !important;
//     background-color: #031D44;
// }